:root {
    --bg-primary: #1E1F22;
    --bg-hover: #2B2D31;
    --bg-profile: #232428;
    --bg-card: rgba(255,255,255,0.03);
    --text-normal: #F2F3F5;
    --text-muted: #949BA4;
    --online: #23A559;
    --accent: #5865F2;
}

.member-list {
    width: 240px;
    padding: 8px;
    background: var(--bg-primary);
    border-left: 1px solid rgba(255,255,255,0.06);
}

.member-header {
    color: var(--text-muted);
    font-size: 12px;
    font-weight: 600;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
}

.member {
    position: relative;
    display: flex;
    align-items: center;
    transition: all 0.2s ease;
}

.avatar-wrapper {
    position: relative;
    width: 32px;
    height: 32px;
}

.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
}

.avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.status {
    position: absolute;
    bottom: -2px;
    right: -2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid var(--bg-primary);
    background: var(--online);
}

.member-name {
    color: var(--text-normal);
    font-size: 14px;
    font-weight: 500;
}

.profile-preview {
    position: absolute;
    right: 200px;
    top: 0px;
    width: 260px;
    background: var(--bg-profile);
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0,0,0,0.2);
    opacity: 0;
    visibility: hidden;
    transform: translateX(8px);
    transition: all 0.2s ease;
    z-index: 1;
    overflow: hidden;
}

.member .profile-preview {
    border: 1px solid red; /* Debugging outline */
}
.member:hover .profile-preview {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
}

.preview-header {
    background: linear-gradient(45deg, #5865F2, #4752C4);
    padding: 12px;
    display: flex;
    gap: 12px;
    position: relative;
}

.preview-avatar {
    width: 50px;
    height: 50px;
    border-radius: 12px;
    overflow: hidden;
    border: 3px solid var(--bg-profile);
}

.preview-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.preview-user-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.preview-username {
    color: white;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 4px;
}

.preview-username i {
    width: 14px;
    height: 14px;
    color: white;
}

.preview-tag {
    color: rgba(255,255,255,0.8);
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.preview-content {
    padding: 12px;
}

.preview-section {
    background: var(--bg-card);
    border-radius: 6px;
    padding: 8px;
    margin-bottom: 8px;
}

.preview-section-title {
    font-size: 11px;
    text-transform: uppercase;
    color: var(--text-muted);
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 6px;
}

.preview-section-title i {
    width: 12px;
    height: 12px;
}

.preview-roles {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.preview-role {
    padding: 4px 8px;
    background: rgba(88,101,242,0.1);
    color: #5865F2;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
}

.preview-role i {
    width: 12px;
    height: 12px;
}

.preview-stats {
    display: flex;
    justify-content: space-between;
    gap: 4px;
}

.preview-stat {
    flex: 1;
    text-align: center;
    padding: 6px;
    background: rgba(255,255,255,0.02);
    border-radius: 4px;
}

.stat-value {
    font-size: 14px;
    font-weight: 600;
    color: var(--text-normal);
}

.stat-label {
    font-size: 10px;
    color: var(--text-muted);
    margin-top: 2px;
}

.activity-card {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px;
    background: rgba(255,255,255,0.02);
    border-radius: 4px;
}

.activity-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(88,101,242,0.1);
    color: #5865F2;
    border-radius: 6px;
}

.activity-icon i {
    width: 14px;
    height: 14px;
}

.activity-info {
    flex: 1;
}

.activity-title {
    font-size: 12px;
    color: var(--text-normal);
    font-weight: 500;
}

.activity-subtitle {
    font-size: 10px;
    color: var(--text-muted);
}

.preview-actions {
    display: flex;
    gap: 4px;
    margin-top: 8px;
}

.preview-button {
    flex: 1;
    padding: 6px;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.preview-button.primary {
    background: var(--accent);
    color: white;
}

.preview-button.secondary {
    background: var(--bg-card);
    color: var(--text-normal);
}

.preview-button i {
    width: 14px;
    height: 14px;
}