.modal {
    background-color: #161719;
    color: #ffffff;
    border-radius: 30px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.6);
    width: 360px;
    padding: 25px;
    text-align: center;
    position: absolute;
    align-self: center;
    top: 80px;
}
.modal h2 {
    margin-top: 0;
    font-size: 1.5em;
    font-weight: bold;
}
.modal p {
    font-size: 1em;
    margin: 10px 0;
    color: #b9bbbe;
}
.upload-button {
    background-color: #1d1e21;
    padding: 20px;
    border: none;
    width: 70px;
    height: 70px;
    margin: 20px auto;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}
.upload-button:hover {
    background-color: #3a3b3e;
}
.upload-button span {
    color: #7289da;
    font-weight: bold;
    font-size: 0.9em;
}
.modal .server-name {
    margin: 20px 0;
    bottom: auto;
}
.modal .server-name input {
    width: calc(100% - 20px);
    padding: 10px;
    border-radius: 12px;
    border: none;
    font-size: 1em;
    background-color: #202225;
    color: #ffffff;
    text-align: center;
    transition: box-shadow 0.3s ease;
}
.server-name input:focus {
    outline: none;
    box-shadow: 0 0 5px #7289da;
}
.buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}
.buttons button {
    padding: 12px 25px;
    border: none;
    border-radius: 25px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    background-color: #444;
    color: #ffffff;
}
.buttons button:hover {
    background-color: #666;
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4);
}
.buttons button:active {
    transform: translateY(0);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);
}
.buttons button:hover {
    background-color: #3a3b3e;
    transform: translateY(-2px);
}
.buttons button:active {
    transform: translateY(0);
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.6);
}
.buttons .create-button:hover {
    background-color: #5a5a5a;
}