* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Arial, sans-serif;
}

body {
  background-color: #1a1c21;
  color: #c7c9d1;
  display: flex;
  height: 100%;

  flex-direction: column;
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  height: 72px;
}

.main-container {
  flex-grow: 1;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
  background: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #2c2f33;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #23272a;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #1b1e21;
}


/** Header **/
.header {
  background: #16181c;
  border-bottom: 1px solid #2b2e33;
  z-index: 100;
}

/** Profile Section **/
.profile-section {
  height: 48px;
  background-color: #14161a;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 10px;
  margin-left: auto;
  border-left: 1px solid #2b2e33;
}

.profile-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #4e54a3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 14px;
}

.profile-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.profile-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-name {
  color: #e5e6eb;
  font-size: 14px;
  font-weight: 500;
}

.profile-tag {
  color: #7a7f87;
  font-size: 12px;
}

.profile-controls {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-left: 8px;
  padding-left: 8px;
}

.profile-icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7a7f87;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s;
}

.profile-icon:hover {
  color: #e5e6eb;
  background-color: rgba(60, 64, 70, 0.3); /* Finom hover effekt */
}

/** Servers Bar **/
.servers-bar {
  height: 72px;
  background: #16181c;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  overflow-y: hidden;
  overflow-x: auto;
}

.servers-bar::-webkit-scrollbar {
  height: 0;
}

.server-icon {
  min-width: 48px;
  height: 48px;
  background: #23262b;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  color: #c7c9d1;
  font-weight: bold;
  user-select: none;
}

.server-icon:hover {
  background: #2b2e33; /* Finom hover */
}

/** Main Container **/
.main-container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

/** Channels Sidebar **/
.channels-sidebar {
  width: 210px;
  background: #1d2025;
  display: flex;
  flex-direction: column;
}

.server-header {
  height: 40px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #e5e6eb;
  font-weight: bold;
  border-bottom: 1px solid #2b2e33;
  cursor: pointer;
}

.server-header:hover {
  background: rgba(60, 64, 70, 0.2); /* Finom, sötétebb hover */
}

.channels-container {
  flex: 1;
  padding: 16px 8px;
  overflow-y: auto;
}

.channel-category {
  color: #60636c;
  font-size: 12px;
  font-weight: 500;
  padding: 8px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.channel {
  width: 200px;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  margin: 1px 0;
  border-radius: 4px;
  color: #7a7f87;
  cursor: pointer;
  user-select: none;
}

.channel:hover {
  background: rgba(60, 64, 70, 0.2);
  color: #e5e6eb;
}

.channel.active {
  background: #4e54a3;
  color: #e5e6eb;
}

.channel::before {
  /**content: "#";**/
  margin-right: 6px;
  font-weight: 500;
}

/** Main Content **/
.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #1a1c21;
}

.chat-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
  border-bottom: 1px solid #2b2e33;
  color: #e5e6eb;
  font-weight: bold;
}

.chat-header .buttons {
  display: flex;
  gap: 20px;
}

.chat-header button {
  align-items: center;
  border: none;
  background: none;
  color: #e5e6eb;
  cursor: pointer;
  font-size: 18px;
}

.chat-header h2 {
  font-size: 24px;
  margin: 5px;
}

.chat-header p {
  font-size: 16px;
  margin: 0px 0px 5px 10px;
}

.messages-area {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
}

.message {
  display: flex;
  gap: 16px;
  padding: 8px;
  margin: 4px 0;
  border-radius: 4px;
}

.message:hover {
  background: rgba(28, 30, 35, 0.3);
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4e54a3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: bold;
  margin: 5px;
}

.user-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.message-content {
  flex: 1;
}

.message-header {
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 4px;
}

.user-name {
  color: #e5e6eb;
  font-weight: 500;
}

.timestamp {
  color: #60636c;
  font-size: 12px;
}

.message-text {
  color: #c7c9d1;
  font-size: 16px;
  line-height: 1.375;
}

.chat-input-container {
  margin: 0 16px 24px;
}

.chat-input {
  width: 100%;
  padding: 12px;
  background: #2b2e33;
  border: none;
  border-radius: 8px;
  color: #e5e6eb;
  font-size: 16px;
  resize: none;
}

.chat-input:focus {
  outline: none;
  background: #2b2e33;
}

.chat-input::placeholder {
  color: #60636c;
}

/** Members Sidebar **/
:root {
  --background-primary: #1d2025;
  --background-secondary: #2b2d31;
  --accent-primary: #5865f2;
  --accent-secondary: #6d77ff;
  --text-primary: #ffffff;
  --text-secondary: #b5bac1;
  --status-online: #23a559;
  --status-idle: #f0b232;
  --status-dnd: #f23f43;
  --status-offline: #80848e;
}

.members-sidebar {
  padding: 10px;
  background: linear-gradient(135deg, var(--background-primary) 0%, var(--background-secondary) 100%);
  min-width: 260px;
  height: 100 vh;
  overflow-y: auto;
}

.members-group {
  font-size: 12px;
  color: var(--text-secondary);
  margin: 15px 0 12px 0;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.03em;
  padding-bottom: 8px;
  border-bottom: 2px solid rgba(255,255,255,0.06);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.member {
  display: flex;
  align-items: center;
  padding: 12px 13px;
  margin: 4px 0;
  border-radius: 12px;
  background: rgba(255,255,255,0.02);
  transition: box-shadow 0.3s ease;
}

.member:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.member-avatar {
  width: 35px;
  height: 35px;
  background: var(--background-secondary);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  font-weight: 600;
  font-size: 15px;
  color: var(--text-primary);
  border: 2px solid rgba(255,255,255,0.1);
}

.member-name {
  flex-grow: 1;
  font-size: 13px;
  font-weight: 500;
  color: var(--text-primary);
}

.status-indicator {
  width: 14px;
  height: 16px;
  margin-left: 12px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.online .status-indicator {
  background-color: var(--status-online);
}

.idle .status-indicator {
  background-color: var(--status-idle);
}

.dnd .status-indicator {
  background-color: var(--status-dnd);
}
.offline .status-indicator {
  background-color: var(--status-offline);
}

.group-counter {
  background: rgba(255,255,255,0.1);
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 11px;
  font-weight: 500;
  color: var(--text-primary);
}

/** Server Container **/
.server-container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-radius: 15px;
  border: 1px solid #2b2e33;
  margin-left: 10px;
}

.server-icon-wrapper {
  position: relative;
  width: 54px;
  height: 54px;
}

.server-icon {
  width: 54px;
  height: 54px;
  background: #1d2025;
  background-image: url('https://cdn.discordapp.com/icons/1242297152970166282/8cc847b2842b286b78517050f4c257c8.webp?size=96');
  background-size: cover;
  display: flex;
  align-items: center;
  border-radius: 14px 0px 0px 14px;
  justify-content: center;
  color: #7a7f87;
  font-weight: 600;
  font-size: 18px;
  position: relative;
  overflow: hidden;
}

.server-bubble {
  background-image: url('https://cdn.discordapp.com/banners/1242297152970166282/9c29762c2f22985bf4faf9714bc8bd5f.webp?size=240');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 150px;
  height: 54px;
  border-radius: 0px 12px 12px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 16px;
  color: #e5e6eb;
}

.server-maneger {
  width: 40px;
}

.server-button {
  font-size: 15px;
  background-color: #14161a;
  border: 1px solid #2b2e33;
  color: white;
  border-radius: 5px;
  padding: 2px 10px;
  margin: 5px;
}

.server-button:hover {
  color: #c7c9d1;
  background-color: rgba(60, 64, 70, 0.3);
}

.new-message {
  position: absolute;
  top: 5px;
  right: 10px;
  color: #7a7f87;
  font-size: 10px;
  font-weight: 500;
}

.server-bubble .server-name {
  position: absolute;
  bottom: 5px;
  left: 10px;
  color: #e5e6eb;
  font-size: 14px;
  font-weight: 500;
}

/* Radial Menu */
.server-list::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(8px);
}

.hex-menu {
  position: fixed;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 1;
  width: 300px;
}

.menu-hex-container {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
  border-radius: 15px;
  transition: all 0.3s;
  background: rgba(32, 34, 37, 0.5); /* Dark grey background */
}

.menu-hex-container:hover {
  background: rgba(54, 57, 63, 0.7); /* Slightly lighter hover effect */
  transform: translateX(10px);
}

.menu-hex-container.active {
  background: rgba(72, 76, 82, 0.7); /* Active state with medium grey */
}

.menu-hex {
  width: 60px;
  height: 69px;
  background-color: #2f3136; /* Darker grey for hex elements */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  cursor: pointer;
  transition: all 0.3s;
  border: 1px solid #202225; /* Dark border to give it depth */
}

.menu-hex-label {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff; /* White text */
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.menu-hex-container:hover .menu-hex {
  background-color: #36393f; /* Lighter grey on hover */
}

.menu-hex-container.active .menu-hex {
  background-color: #4f545c; /* Slightly bluish grey for active */
  box-shadow: 0 0 15px rgba(114, 137, 218, 0.3); /* Blue shadow for active effect */
}

.radial-menu {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 400px;
  z-index: 1;
}

.center-hex {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 132px;
  background-color: #202225; /* Darker grey for center hex */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px rgba(114, 137, 218, 0.3); /* Blue glow for center */
  pointer-events: none;
}

.center-hex img {
  width: 120px;
}

.radial-menu .menu-item {
  position: absolute;
  width: 60px;
  height: 69px;
  background-color: #2f3136; /* Dark grey for menu items */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  border: none;
  outline: none;
  color: #ffffff; /* White text for icons */
  border: 1px solid #202225;
  overflow: hidden;
}

.radial-menu .menu-item:hover {
  background-color: #36393f; /* Lighter grey for hover */
  transform: scale(1.1);
  border-color: #4a5568;
  box-shadow: 0 0 20px rgba(54, 57, 63, 0.4);
}

.radial-menu .menu-item.active {
  background-color: #4f545c;
  box-shadow: 0 0 20px rgba(114, 137, 218, 0.3);
}

.radial-menu .menu-item i {
  font-size: 24px;
  color: #ffffff;
}

.radial-menu .menu-item.image-hex {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.radial-menu .menu-item.image-hex::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  transition: all 0.3s;
}

.radial-menu .menu-item.image-hex:hover::before {
  transform: scale(1.1);
}

/* Radiális menü pozíciók - 8 elem */
.radial-menu .menu-item:nth-child(1) { left: 170px; top: 0; }      /* Felső */
.radial-menu .menu-item:nth-child(2) { left: 280px; top: 40px; }   /* Jobb felső */
.radial-menu .menu-item:nth-child(3) { left: 320px; top: 165px; }  /* Jobb */
.radial-menu .menu-item:nth-child(4) { left: 280px; top: 290px; }  /* Jobb alsó */
.radial-menu .menu-item:nth-child(5) { left: 170px; top: 330px; }  /* Alsó */
.radial-menu .menu-item:nth-child(6) { left: 60px; top: 290px; }   /* Bal alsó */
.radial-menu .menu-item:nth-child(7) { left: 20px; top: 165px; }   /* Bal */
.radial-menu .menu-item:nth-child(8) { left: 60px; top: 40px; }    /* Bal felső */

.hex-content {
  position: fixed;
  top: 100px;
  right: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(32, 34, 37, 0.5);
  width: 80px;
  height: 80px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.hex-content i {
  font-size: 4rem;
  color: #ffffff;
}

.hex-content:hover {
  background-color: rgba(32, 34, 37, 0.7);
}

#selected-channel {
  color: var(--text-secondary); 
  background: var(--hover-bg)
}
#selected-channel:hover {
  color: #e5e6eb;
}
#selected-guild {
  border: 1px solid rgb(153, 153, 153);    
}


/* spinner */
.loaderparent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loader {
  display: block;
  margin: auto;
  position: relative;
  width: 48px;
  height: 48px;
}

.loader:before,
.loader:after {
  content:"";
  display: block;
  border: 32px solid transparent;
  border-top-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  animation: weld-rotate 2s infinite ease-in;
}

.loader:before {
  border-color: transparent  transparent transparent #FF3D00;
  animation-delay: 0.5s;
}

@keyframes weld-rotate {
  0% , 25% {transform: rotate(0deg)}
  50% , 75% {transform: rotate(180deg)}
  100% {transform: rotate(360deg)}
}
/* spinner */
.banner-img {
  width: 210px;
  border-radius: 0px 12px 0px 0px;
  border: 2px solid #2b2e33;
}

.avatar-container {
  position: relative;
  display: inline-block; 
}

.crown-icon {
  position: absolute;
  top: -20px;
  left: 1px; 
  width: 30px;
  height: 30px;
  pointer-events: none;
}

.reactions {
  margin: 5px;
}

.reaction {
  background-color: #640c0c58;
  border-radius: 5px;
  padding: 2px;
  margin-right: 4px;
  border: solid 1px #9a0000;
}
.no-friends, .no-channels {
  text-align: center;
}
.-no-friends p {
  text-align: justify;
}






/* Styles for PromptMenu */
.prompt-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.prompt-container {
  color: #f5f5f5;
  background-color: #1a1c21;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.prompt-container h1 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.prompt-container p {
  font-size: 1em;
  margin-bottom: 15px;
}

.prompt-container label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.9em;
}

.prompt-container input[type="text"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  background-color: #f5f5f5;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  background-color: #444;
  color: #f5f5f5;
  border-color: #666;
}


.prompt-container button {
  padding: 10px 15px;
  font-size: 0.9em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s ease;
}

.prompt-container button:first-of-type {
  background-color: #d9534f;
  color: white;
}

.prompt-container button:first-of-type:hover {
  background-color: #c9302c;
}

.prompt-container button:last-of-type {
  background-color: #5bc0de;
  color: white;
}

.prompt-container button:last-of-type:hover {
  background-color: #31b0d5;
}
