#context-menu {
    position: absolute;
    background: #2b2d31;
    border-radius: 4px;
    padding: 6px;
    width: 220px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    animation: scaleIn 0.15s ease;
    transform-origin: top left;
}

@keyframes scaleIn {
    0% {
        transform: scale(0.95);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.context-section {
    margin-bottom: 4px;
}

.menu-item button {
    padding: 6px 8px;
    margin: 2px 0;
    border-radius: 2px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    position: relative;
    color: #dbdee1;
    font-size: 14px;
    transition: all 0.1s ease;
}

.menu-item:hover {
    background: #404249;
}

.menu-item.danger button {
    color: #ed4245;
}

.menu-item.danger:hover {
    background: rgba(237, 66, 69, 0.1);
}

.menu-item svg {
    width: 16px;
    height: 16px;
    opacity: 0.9;
    stroke-width: 2;
    color: currentColor;
}

.menu-item .content {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-item .shortcut {
    color: #949ba4;
    font-size: 12px;
    margin-left: 16px;
}

.menu-separator {
    height: 1px;
    background: #3f4147;
    margin: 4px 2px;
}

.submenu-arrow {
    margin-left: auto;
    opacity: 0.6;
}

.menu-header {
    padding: 6px 8px;
    font-size: 12px;
    font-weight: 600;
    color: #949ba4;
    text-transform: uppercase;
}
