:root {
  --sidebar-width: 300px;
  --sidebar-collapsed-width: 80px;
  --primary-color: #5865F2;
  --secondary-color: #4752C4;
  --bg-darker: #2B2D31;
  --bg-light: #383A40;
  --text-secondary: #B5BAC1;
  --hover-bg: #404249;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: var(--sidebar-width);
  background: #1d2025;
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.freen-footer {
  margin-top: auto;
  text-align: center;
  padding: 1rem; 
}

.freen-footer i {
  font-size: 1.5rem;
  color: #6c757d; 
}

.sidebar.collapsed {
  width: var(--sidebar-collapsed-width);
}

.toggle-btn {
  width: 100%;
  padding: 16px 20px;
  background: var(--bg-darker);
  border: none;
  color: var(--text-primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1em;
  font-weight: 600;
  transition: all 0.3s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 2;
}

.toggle-btn:hover {
  background: var(--hover-bg);
}

.toggle-btn .label {
  transition: opacity 0.2s ease;
}

.collapsed .toggle-btn {
  justify-content: center;
}

.collapsed .toggle-btn .label {
  display: none;
}

.arrow {
  transition: transform 0.3s ease;
  transform: rotate(180deg);
}

.collapsed .arrow {
  transform: rotate(0deg);
}

.freen-friends-list {
  padding: 16px;
  overflow-y: auto;
  height: calc(100vh - 57px);
  transition: padding 0.3s ease;
}

.collapsed .freen-friends-list {
  padding: 10px 14px;
}

/* Scrollbar styling */
.freen-friends-list::-webkit-scrollbar {
  width: 8px;
}

.freen-friends-list::-webkit-scrollbar-track {
  background: var(--bg-darker);
}

.freen-friends-list::-webkit-scrollbar-thumb {
  background-color: var(--hover-bg);
  border-radius: 4px;
  border: 2px solid var(--bg-darker);
}

.freen-friends-list::-webkit-scrollbar-thumb:hover {
  background-color: var(--bg-light);
}

/* Firefox support */
.freen-friends-list {
  scrollbar-width: thin;
  scrollbar-color: var(--hover-bg) var(--bg-darker);
}

.freen-friend-category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-secondary);
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 600;
  padding: 8px 10px;
  letter-spacing: 0.02em;
  transition: all 0.3s ease;
  cursor: pointer;
}

.freen-category-text {
  flex: 1;
  white-space: nowrap;
}

.freen-category-icon {
  display: flex;
  align-items: center;
  gap: 5px;
}

.freen-circle-icon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.freen-down-arrow-icon {
  transition: transform 0.3s ease;
}

.collapsed .freen-category-text {
  display: none;
}

.freen-friend-category.collapsed .freen-down-arrow-icon {
  transform: rotate(-90deg);
}

.freen-friend {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  margin: 4px 0;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  justify-content: flex-start;
  transition: background 0.2s ease, padding 0.3s ease, margin 0.3s ease;
}

.collapsed .freen-friend {
  justify-content: center;
  padding: 8px 0;
  margin: 4px 0;
}

.freen-friend:hover {
  background: var(--hover-bg);
}

.collapsed .freen-friend:hover {
  background: none;
}

.collapsed .freen-friend:hover .freen-hexagon {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.freen-hexagon-wrapper {
  width: 50px;
  height: 55px;
  position: relative;
  margin-right: 15px;
  flex-shrink: 0;
  transition: margin 0.3s ease;
}

.collapsed .freen-hexagon-wrapper {
  margin: 0 auto;
  margin-right: 0;
}

.freen-hexagon {
  position: absolute;
  width: 40px;
  height: 45px;
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.2em;
  color: white;
  transition: transform 0.3s ease;
}

.freen-status-indicator {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 3px solid var(--bg-darker);
  z-index: 1;
}

/* Status indicators only show when collapsed */
.sidebar:not(.collapsed) .freen-status-indicator {
  display: none;
}

.freen-friend-info {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 1;
  visibility: visible;
}

.collapsed .freen-friend-info {
  opacity: 0;
  visibility: hidden;
}

.freen-friend-name {
  font-size: 1em;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.freen-status {
  font-size: 0.9em;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  gap: 6px;
}

.freen-activity {
  font-size: 0.85em;
  color: var(--text-secondary);
  opacity: 0.8;
  margin-top: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.freen-status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.freen-online {
  background-color: #3BA55C; /* Green */
}

.freen-idle {
  background-color: #FAA61A; /* Yellow */
}

.freen-dnd {
  background-color: #ED4245; /* Red */
}

.freen-offline {
  background-color: #747F8D; /* Gray */
}

.freen-friend-divider {
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
  margin: 8px 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 1;
  visibility: visible;
}

.collapsed .freen-friend-divider {
  opacity: 0;
  visibility: hidden;
}

/* Friend list group collapse styles */
.freen-friend-list-group {
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: 1000px; /* Large enough to contain full content */
}

.freen-friend-list-group.collapsed {
  max-height: 0;
}